import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"


import "../styles/layout.css"

const Layout = ({ pathname, title, children }) => {
  
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (pathname === rootPath) {
    header = (
      <div>
      </div>)
  } else {
    header = title
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1/2)} ${rhythm(1)}`,
      }}
    >
   

      <header>{header}</header>
      <main>{children}</main>
      <footer>
        <div
        style={{
          fontSize: `16px`,
        }}
        >
          <Link to={`/about`}>About</Link>   &bull; <a href={'https://causalflows.substack.com'}>Newsletter</a>
        </div>
      </footer>
    </div>
  )
}

export default Layout
